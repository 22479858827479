import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class LoggedInService {

    constructor(
        private AuthService: AuthService,
        private router: Router
    ) { }

    canActivate() {

        const loggedIn = this.AuthService.isLoggedIn();
        if (loggedIn) {
            return true;
        } else {
            this.router.navigate(['/auth']);
            return false;
        }

    }

}
